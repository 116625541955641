$(() => {
  // 実績一覧の検索ページでのみ読み込み
  if ($('body').data('path') === '/works/') {
    $('.search_btn_category').on('click', function () {
      // ビジネスカテゴリボタンの選択非選択
      var status = ($(this).attr('data-current') === 'false');
      $(this).attr('data-current', status);

      // ボジネスカテゴリのPOSTデータ用非表示チェックボックの選択・非選択
      var bc_id = $(this).data('bc-id');
      var check_box = $('#q_works_business_categories_business_category_id_in_' + bc_id);
      check_box.prop('checked', status);

      display_tags();
    });

    // POSTデータ用の非表示にしているチェックボックの値を更新
    var check_boxes = $('[type=checkbox][name="q[works_business_categories_business_category_id_in][]"]');
    check_boxes.each(function (i, v) {
      var bs_id = v.id.replace('q_works_business_categories_business_category_id_in_', '');

      if (v.checked) {
        $('.search_btn_category[data-bc-id=' + bs_id + ']').attr('data-current', true);
      }
    });

    // 関連タグの表示非表示
    display_tags();
  }

  // 選択されているビジネスカテゴリに対応するタグのみを表示
  function display_tags() {
    var selected_bc = $('.search_btn_category[data-current=true]');

    // 一旦すべて非表示&無効化(postされないようにする)
    $('.search_works_by_tags').hide();
    $('.search_works_by_tags').children('[type=checkbox]').attr('disabled', true);

    selected_bc.each(function (i, element) {
      gon.bc_tags[$(element).data('bc-id')].forEach(function (tag_id) {
        $('#search_works_by_tag_' + tag_id).show();
        $('#q_works_tags_tag_id_in_' + tag_id).attr('disabled', false);
      });
    });
  }
});
