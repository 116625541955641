import {toggleBudgets} from "../src/public/contact_budget";

require('../src/public/works_searchable');
import { onClickContactInquiries } from '../src/public/contact_inquiries_limit'

$(() => {
  // CONTACTのフォームの問い合わせ項目のtoggleをデフォルトopenにする
  $('.form-toggle-btn').toggleClass('close');
  $('.form-toggle-contents').slideDown();

  // お問い合わせ項目の選択数制限
  $(".mail-input-checkbox input[type='checkbox']").on('click', onClickContactInquiries);

  // ご予算の表示非表示
  // 初期処理
  toggleBudgets();
  // お問い合わせ項目クリック時の処理
  $(".mail-input-checkbox input[type='checkbox']").on('click', toggleBudgets);

  // submitボタンを2重に押せないようにする
  $('input[type="submit"]').on('click', function () {
    $(this).css('pointer-events', 'none');
  });

  // home掲載するTechBlogの新着記事を取得する
  $('#tech_blog_area').each(function(_i, val) {
    $.ajax({
      url: './home/tech_blog',
      type: 'GET'
    }).done(function(data) {
        $(val).html(data);
    });
  })
});
