export const onClickContactInquiries = () => {
  const checkedNum = $(".mail-input-checkbox input[type='checkbox']:checked").length;
  const $notChecked = $(".mail-input-checkbox input[type='checkbox']").not(":checked")

  // 制限数ちょうどのとき
  if(checkedNum >= gon.contact_inquiries_limit){
    $notChecked.attr('disabled', true);
  // 制限数以下のとき
  }else{
    $notChecked.attr('disabled', false);
  }
}
